import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import home from '../../assets/icons/home.svg';
import logo from '../../assets/images/logo.png';
import API from '../../axiosApi';
import { USER_ROLE } from '../../helper/constant';
import { ERROR_MESSAGE, validateLoginForm } from '../../helper/validate';
import ROUTE_PATH from '../../router/routePath';
import './styles.css';

const Login = () => {
  const navigate = useNavigate();

  const [account, setAccount] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    if (userInfo) {
      const { userRole, user, token } = userInfo;
      switch (userRole) {
        case USER_ROLE.admin:
        case USER_ROLE.customer:
        case USER_ROLE.general:
        case USER_ROLE.stormcloud:
          localStorage.setItem('author', JSON.stringify(user));
          localStorage.setItem('token', token.accessToken);
          navigate(ROUTE_PATH.home);
          break;
        default:
          setErrorMessage(
            'Your account does not have permission to access this page'
          );
          break;
      }
    }
  }, [navigate, userInfo]);

  const formSubmit = async (e) => {
    e.preventDefault();
    const isValidForm = validateLoginForm(account);
    if (!isValidForm) {
      setErrorMessage(ERROR_MESSAGE.login);
      return;
    }
    const response = await API.post('auth/login', account).catch(() => {
      setErrorMessage('Something went wrong');
    });
    const user = response?.data?.user;
    const token = response?.data?.token;

    if (token) {
      if (user?.isDisabled) {
        setErrorMessage(
          'Your account was disabled. Contact with Admin, please'
        );
      } else {
        const userRole = user?.role?.[0]?.name;
        setUserInfo({ user, token, userRole });
      }
    } else {
      setErrorMessage(ERROR_MESSAGE.login);
    }
  };

  const handleChangeAccount = (e) => {
    const { value, name } = e.target;
    setAccount((preState) => ({
      ...preState,
      [name]: value.trim(),
    }));
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  return (
    <div className="loginPage">
      <div className="home">
        <img src={home} alt="" className="iconHome" />
      </div>
      <form className="loginInner" onSubmit={formSubmit}>
        <h1 className="loginTitle">
          PLEASE LOGIN WITH THE CREDENTIAL THE SALES TEAM ASSIGNED YOU
        </h1>
        <input
          type="text"
          className="loginInput"
          placeholder="Email"
          name="email"
          onChange={handleChangeAccount}
        />
        <input
          type="password"
          className="loginInput"
          placeholder="Password"
          name="password"
          onChange={handleChangeAccount}
        />
        <p className="errorText">{errorMessage}</p>
        <p className="loginDescription">
          If you have need help with password recovery; please email your sales
          contact
        </p>
        <button type="submit" className="loginBtn">
          Login
        </button>
      </form>
      <img src={logo} alt="" className="loginLogo" />
    </div>
  );
};

export default Login;
