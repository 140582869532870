import React from "react";

import "./styles.scss";

const AccessDeny = () => {
  return (
    <div className={'access-denied-container'}>
      <h2>Oops!</h2>
      <h1>403</h1>
      <p>Sorry! You don't access this page</p>
      <a href={'/'}>Go back home</a>
    </div>
  );
};

export default AccessDeny;
