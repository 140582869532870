import React from 'react';
import { Navigate } from 'react-router-dom';
import SideBar from '../components/SideBar/SideBar';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import ROUTE_PATH from './routePath';

const Srt = React.lazy(() => import('../pages/Srt'));
const SrtEditor = React.lazy(() => import('../pages/SrtEditor/SrtEditor'));
const UploadFile = React.lazy(() => import('../pages/UploadFile/UploadFile'));
const AdvertisementBlock = React.lazy(() => import('../pages/AdvertisementBlock/AdvertisementBlock'));
const AdvertisementBlockEditor = React.lazy(() => import('../pages/AdvertisementBlockEditor/AdvertisementBlockEditor'));
const Storage = React.lazy(() => import('../pages/Storage/Storage'));
const Fillers = React.lazy(() => import('../pages/Fillers/Fillers'));
const Bucket = React.lazy(() => import('../pages/Bucket/Bucket'));
const BucketEditor = React.lazy(() => import('../pages/BucketEditor/BucketEditor'));
const Users = React.lazy(() => import('../pages/Users'));
const UserEditor = React.lazy(() => import('../pages/UserEditor'));
const Analytics = React.lazy(() => import('../pages/Analytics/Analytics'));
const Hls = React.lazy(() => import('../pages/Hls/Hls'));
const Mrss = React.lazy(() => import('../pages/Mrss/Mrss'));
const MrssEditor = React.lazy(() => import('../pages/MrssEditor/MrssEditor'));
const LiveStream = React.lazy(() => import('../pages/LiveStream/LiveStream'));
const Broadcast = React.lazy(() => import('../pages/Broadcast/Broadcast'));
const AdStorm = React.lazy(() => import('../pages/AdStorm'));
const HlsEditor = React.lazy(() => import('../pages/HlsEditor/HlsEditor'));
const Scheduler = React.lazy(() => import('../pages/Scheduler/Scheduler'));
const StorageEditor = React.lazy(() => import('../pages/StorageEditor/StorageEditor'));
const NotFound = React.lazy(() => import('../pages/NotFound/NotFound'));
const Categories = React.lazy(() => import('../pages/Categories/Categories'));
const CategoryEditor = React.lazy(() => import('../pages/CategoryEditor/CategoryEditor'));
const Channels = React.lazy(() => import('../pages/Channels/Channels'));
const ChannelEditor = React.lazy(() => import('../pages/ChannelEditor/ChannelEditor'));
const RTMP = React.lazy(() => import('../pages/RTMP'));

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  return token ? children : <Navigate to={ROUTE_PATH.login} />;
};

const ROUTES = [
  {
    path: ROUTE_PATH.home,
    component: Home,
    guard: PrivateRoute,
    layout: SideBar,
  },
  {
    path: ROUTE_PATH.login,
    component: Login,
  },
  {
    path: ROUTE_PATH.liveStream,
    component: LiveStream,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.provision,
    component: Broadcast,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.adStorm,
    component: AdStorm,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Stormcloud'],
  },
  {
    path: ROUTE_PATH.analytics,
    component: Analytics,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.users,
    component: Users,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin']
  },
  {
    path: ROUTE_PATH.userCreator,
    component: UserEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin']
  },
  {
    path: ROUTE_PATH.userEditor,
    component: UserEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin']
  },
  {
    path: ROUTE_PATH.hls,
    component: Hls,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud'],
  },
  {
    path: ROUTE_PATH.mrss,
    component: Mrss,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.mrssCreator,
    component: MrssEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.mrssEditor,
    guard: PrivateRoute,
    component: MrssEditor,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.srt,
    component: Srt,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.srtCreator,
    component: SrtEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.srtEditor,
    component: SrtEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.hlsCreator,
    component: HlsEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.hlsEditor,
    component: HlsEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.scheduler,
    component: Hls,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.schedulerCreator,
    component: Scheduler,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.schedulerEditor,
    component: Scheduler,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.storage,
    component: Storage,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.fillers,
    component: Fillers,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'General']
  },
  {
    path: ROUTE_PATH.storageEditor,
    component: StorageEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.storageCreator,
    component: StorageEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.notFound,
    component: NotFound,
    guard: PrivateRoute,
    layout: SideBar,
  },
  {
    path: ROUTE_PATH.categories,
    component: Categories,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.categoryEditor,
    component: CategoryEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.categoryCreator,
    component: CategoryEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.channels,
    component: Channels,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.channelEditor,
    component: ChannelEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.channelCreator,
    component: ChannelEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.advertisementBlock,
    component: AdvertisementBlock,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.advertisementBlockEditor,
    component: AdvertisementBlockEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.advertisementBlockCreator,
    component: AdvertisementBlockEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.rtmp,
    component: RTMP,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  },
  {
    path: ROUTE_PATH.bucket,
    component: Bucket,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'General']
  },
  {
    path: ROUTE_PATH.bucketCreate,
    component: BucketEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'General']
  },
  {
    path: ROUTE_PATH.bucketEdit,
    component: BucketEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'General']
  },
  {
    path: ROUTE_PATH.uploadFile,
    component: UploadFile,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Stormcloud']
  }
];

export default ROUTES;
