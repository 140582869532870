import React, { Fragment, Suspense } from "react";
import Spinner from "../components/Spinner/Spinner";
import AccessDeny from "../components/AccessDeny/AccessDeny";
import {Route, Routes} from "react-router";

/**
 * routes: {
    path: string;
    component: React.LazyExoticComponent<ComponentType<unknown>> | ComponentType<unknown>;
    exact?: boolean;
    guard?: React.LazyExoticComponent<ComponentType<unknown>> | ComponentType<unknown>;
    layout?: React.LazyExoticComponent<ComponentType<unknown>> | ComponentType<unknown>;
    routes?: IRoute[];
  }[]
 */
function renderRoutes(routes, fallback = <Spinner/>, prefix = '') {
  return routes && Array.isArray(routes) ? (
    <Suspense fallback={fallback}>
      <Routes>
        {routes.map((route) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const path = prefix + route.path;
          const roles = route.hasOwnProperty('roles') ? route.roles : [];

          const author = JSON.parse(localStorage.getItem('author'));
          const name = author?.role?.[0]?.name;

          const hasAccess = !roles.length || roles.includes(name);

          return (
            <Route
              key={path}
              path={path}
              exact={route.exact}
              element={
                <Guard>
                  <Layout>
                    {hasAccess ? <Component/> : <AccessDeny />}
                  </Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  ) : null;
}

export function AppRoutes({ routesConfig, fallback }) {
  return renderRoutes(routesConfig, fallback);
}

